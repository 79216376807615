import React from "react"

// import Badge from "./src/components/common/badge"

export const wrapPageElement = ({ element }) => (
  <>
    {element}
    {/* <Badge /> */}
  </>
)

export const onClientEntry = () => {
  window.onload = () => {
    addScriptTag(
      "https://d335luupugsy2.cloudfront.net/js/loader-scripts/83f60edf-868d-40ab-a6e7-3af3e2f37a35-loader.js"
    )
  }
}

const addScriptTag = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}
